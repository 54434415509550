import React from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import './App.css';
import 'bootstrap/dist/css/bootstrap.css'
import Home from './pages/Home/Home'
import Header from './components/Header';
import Footer from './components/Footer';
import Work from './pages/Work/Work';
import Blog from './pages/Blog/Blog';
import BlogDetail from './pages/BlogDetail/BlogDetail'
import Contact from './pages/Contact/Contact';
import Career from './pages/Career/Career';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route exact path="/work" element={<Work/>} />
          <Route exact path="/blog" element={<Blog/>} />
          <Route exact path="/blog-detail" element={<BlogDetail/>} />
          <Route exact path="/contact" element={<Contact/>} />
          <Route exact path="/career" element={<Career/>} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
