import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Lightbox from 'bs5-lightbox';

const options = {
	keyboard: true,
	size: 'fullscreen'
};

function Work() {
	useEffect(() => {
		const handleLightboxClick = (e) => {
			e.preventDefault();
			const lightbox = new Lightbox(e.currentTarget, options);
			lightbox.show();
		};
		const lightboxElements = document.querySelectorAll('.my-lightbox-toggle');
		lightboxElements.forEach((el) => {
			el.addEventListener('click', handleLightboxClick);
		});
		return () => {
			lightboxElements.forEach((el) => {
				el.removeEventListener('click', handleLightboxClick);
			});
		};
	}, []);

	return (
		<>
			{/* <div className="bradcrumb light-bg">
				<div className="container">
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb mb-0">
							<li className="breadcrumb-item"><a href="/">Home</a></li>
							<li className="breadcrumb-item active" aria-current="page">Work</li>
						</ol>
					</nav>
				</div>
			</div> */}
			<div className="worl-section">
				<div className="container">
					<div className="heading-block mb-5 mt-2">
						<h1 className="font-44 color-black text-uppercase text-center">OUR Work</h1>
					</div>
				</div>

				<div className="container-fluid">
					<div className="main-gallary">
						<div className="masonry">
							<a href="img/1.jpg" className="brick my-lightbox-toggle">
								<img src="img/1.jpg" alt="Bayaart" title="Bayaart" />
							</a>
							<a href="img/2.jpg" className="brick my-lightbox-toggle">
								<img src="img/2.jpg" alt="Bayaart" title="Bayaart" />
							</a>
							<a href="img/5.jpg" className="brick my-lightbox-toggle">
								<img src="img/5.jpg" alt="Bayaart" title="Bayaart" />
							</a>
							<a href="img/3.jpg" className="brick my-lightbox-toggle">
								<img src="img/3.jpg" alt="Bayaart" title="Bayaart" />
							</a>
							<a href="img/16.jpg" className="brick my-lightbox-toggle">
								<img src="img/16.jpg" alt="Bayaart" title="Bayaart" />
							</a>
							<a href="img/6.jpg" className="brick my-lightbox-toggle">
								<img src="img/6.jpg" alt="Bayaart" title="Bayaart" />
							</a>
							<a href="img/images/easter-eggs.jpg" className="brick my-lightbox-toggle">
								<img src="img/images/easter-eggs.jpg" alt="Bayaart" title="Bayaart" />
							</a>
							<a href="img/5.jpg" className="brick my-lightbox-toggle">
								<img src="img/5.jpg" alt="Bayaart" title="Bayaart" />
							</a>
							<a href="img/images/lemons.jpg" className="brick my-lightbox-toggle">
								<img src="img/images/lemons.jpg" alt="Bayaart" title="Bayaart" />
							</a>
							<a href="img/4.jpg" className="brick my-lightbox-toggle">
								<img src="img/4.jpg" alt="Bayaart" title="Bayaart" />
							</a>
						</div>
					</div>
				</div>
				{/* <div className="row">
							<div className="col-md-6">
								<div className="gallery-box">
										<div className="gallery">
										<a href="https://unsplash.it/1200/768.jpg?image=251" className="my-lightbox-toggle">
											<img src="https://unsplash.it/600.jpg?image=251" className="" />
										</a>
										<a href="https://unsplash.it/1200/768.jpg?image=252" className="my-lightbox-toggle">
											<img src="https://unsplash.it/600.jpg?image=252" className="" />
										</a>
										<a href="https://unsplash.it/1200/768.jpg?image=253" className="my-lightbox-toggle">
											<img src="https://unsplash.it/600.jpg?image=253" className="" />
										</a>
										<a href="https://picsum.photos/id/823/400/400" className="my-lightbox-toggle">
											<img src="https://picsum.photos/id/823/400/400" className="" />
										</a>
										</div>
										
								</div>
							</div>
							<div className="col-md-6">
								<div className="gallery-box">
										<div className="gallery">
										<a href="https://unsplash.it/1200/768.jpg?image=254" className="my-lightbox-toggle">
											<img src="https://unsplash.it/1200/768.jpg?image=254" className="" />
										</a>
										<a href="https://unsplash.it/1200/768.jpg?image=255" className="my-lightbox-toggle">
											<img src="https://unsplash.it/1200/768.jpg?image=255" className="" />
										</a>
										<a href="https://unsplash.it/1200/768.jpg?image=256" className="my-lightbox-toggle">
											<img src="https://unsplash.it/600.jpg?image=256" className="" />
										</a>
										<a href="img/images/cherry-plant.jpg" className="my-lightbox-toggle">
											<img src="img/images/cherry-plant.jpg" className="" />
										</a>
										</div>
										
								</div>
							</div>
						</div> */}
			</div>


		</>
	);
}

export default Work;
